import { default as loginglRf2HxFYXMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/auth/login.vue?macro=true";
import { default as request_45passwordZkPV9kEIuiMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/auth/request-password.vue?macro=true";
import { default as _91token_93lXAIoEXvSJMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/auth/reset-password/[token].vue?macro=true";
import { default as _91token_930GqmPk27u5Meta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/auth/setup-password/[token].vue?macro=true";
import { default as bekabeldpXFpp2rHa1Meta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/beheer/bekabeld.vue?macro=true";
import { default as importgRpdOgvJqLMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/beheer/switch-ports/import.vue?macro=true";
import { default as index9hjSB3pUbnMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/beheer/switch-ports/index.vue?macro=true";
import { default as calculatorA7LsEW1d6dMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/calculator.vue?macro=true";
import { default as index3gj1gmuFUjMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/index.vue?macro=true";
import { default as indexqkDRBwKLhNMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/klanten/[slug]/index.vue?macro=true";
import { default as indexRLdBJJCzQ6Meta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/klanten/index.vue?macro=true";
import { default as indexVKQdFsGe7SMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/medewerkers/[uuid]/index.vue?macro=true";
import { default as createyax9n4M9rTMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/medewerkers/create.vue?macro=true";
import { default as indexAQeHAYD59nMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/medewerkers/index.vue?macro=true";
import { default as indexSW1vIdCF0TMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/orders/[slug]/index.vue?macro=true";
import { default as indexFTPCQmRmVcMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/profile/index.vue?macro=true";
import { default as statusWe4ssuO2WdMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/status.vue?macro=true";
export default [
  {
    name: loginglRf2HxFYXMeta?.name ?? "auth-login",
    path: loginglRf2HxFYXMeta?.path ?? "/auth/login",
    meta: loginglRf2HxFYXMeta || {},
    alias: loginglRf2HxFYXMeta?.alias || [],
    redirect: loginglRf2HxFYXMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: request_45passwordZkPV9kEIuiMeta?.name ?? "auth-request-password",
    path: request_45passwordZkPV9kEIuiMeta?.path ?? "/auth/request-password",
    meta: request_45passwordZkPV9kEIuiMeta || {},
    alias: request_45passwordZkPV9kEIuiMeta?.alias || [],
    redirect: request_45passwordZkPV9kEIuiMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/auth/request-password.vue").then(m => m.default || m)
  },
  {
    name: _91token_93lXAIoEXvSJMeta?.name ?? "auth-reset-password-token",
    path: _91token_93lXAIoEXvSJMeta?.path ?? "/auth/reset-password/:token()",
    meta: _91token_93lXAIoEXvSJMeta || {},
    alias: _91token_93lXAIoEXvSJMeta?.alias || [],
    redirect: _91token_93lXAIoEXvSJMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_930GqmPk27u5Meta?.name ?? "auth-setup-password-token",
    path: _91token_930GqmPk27u5Meta?.path ?? "/auth/setup-password/:token()",
    meta: _91token_930GqmPk27u5Meta || {},
    alias: _91token_930GqmPk27u5Meta?.alias || [],
    redirect: _91token_930GqmPk27u5Meta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/auth/setup-password/[token].vue").then(m => m.default || m)
  },
  {
    name: bekabeldpXFpp2rHa1Meta?.name ?? "beheer-bekabeld",
    path: bekabeldpXFpp2rHa1Meta?.path ?? "/beheer/bekabeld",
    meta: bekabeldpXFpp2rHa1Meta || {},
    alias: bekabeldpXFpp2rHa1Meta?.alias || [],
    redirect: bekabeldpXFpp2rHa1Meta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/beheer/bekabeld.vue").then(m => m.default || m)
  },
  {
    name: importgRpdOgvJqLMeta?.name ?? "beheer-switch-ports-import",
    path: importgRpdOgvJqLMeta?.path ?? "/beheer/switch-ports/import",
    meta: importgRpdOgvJqLMeta || {},
    alias: importgRpdOgvJqLMeta?.alias || [],
    redirect: importgRpdOgvJqLMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/beheer/switch-ports/import.vue").then(m => m.default || m)
  },
  {
    name: index9hjSB3pUbnMeta?.name ?? "beheer-switch-ports",
    path: index9hjSB3pUbnMeta?.path ?? "/beheer/switch-ports",
    meta: index9hjSB3pUbnMeta || {},
    alias: index9hjSB3pUbnMeta?.alias || [],
    redirect: index9hjSB3pUbnMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/beheer/switch-ports/index.vue").then(m => m.default || m)
  },
  {
    name: calculatorA7LsEW1d6dMeta?.name ?? "calculator",
    path: calculatorA7LsEW1d6dMeta?.path ?? "/calculator",
    meta: calculatorA7LsEW1d6dMeta || {},
    alias: calculatorA7LsEW1d6dMeta?.alias || [],
    redirect: calculatorA7LsEW1d6dMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/calculator.vue").then(m => m.default || m)
  },
  {
    name: index3gj1gmuFUjMeta?.name ?? "index",
    path: index3gj1gmuFUjMeta?.path ?? "/",
    meta: index3gj1gmuFUjMeta || {},
    alias: index3gj1gmuFUjMeta?.alias || [],
    redirect: index3gj1gmuFUjMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexqkDRBwKLhNMeta?.name ?? "klanten-slug",
    path: indexqkDRBwKLhNMeta?.path ?? "/klanten/:slug()",
    meta: indexqkDRBwKLhNMeta || {},
    alias: indexqkDRBwKLhNMeta?.alias || [],
    redirect: indexqkDRBwKLhNMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/klanten/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRLdBJJCzQ6Meta?.name ?? "klanten",
    path: indexRLdBJJCzQ6Meta?.path ?? "/klanten",
    meta: indexRLdBJJCzQ6Meta || {},
    alias: indexRLdBJJCzQ6Meta?.alias || [],
    redirect: indexRLdBJJCzQ6Meta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/klanten/index.vue").then(m => m.default || m)
  },
  {
    name: indexVKQdFsGe7SMeta?.name ?? "medewerkers-uuid",
    path: indexVKQdFsGe7SMeta?.path ?? "/medewerkers/:uuid()",
    meta: indexVKQdFsGe7SMeta || {},
    alias: indexVKQdFsGe7SMeta?.alias || [],
    redirect: indexVKQdFsGe7SMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/medewerkers/[uuid]/index.vue").then(m => m.default || m)
  },
  {
    name: createyax9n4M9rTMeta?.name ?? "medewerkers-create",
    path: createyax9n4M9rTMeta?.path ?? "/medewerkers/create",
    meta: createyax9n4M9rTMeta || {},
    alias: createyax9n4M9rTMeta?.alias || [],
    redirect: createyax9n4M9rTMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/medewerkers/create.vue").then(m => m.default || m)
  },
  {
    name: indexAQeHAYD59nMeta?.name ?? "medewerkers",
    path: indexAQeHAYD59nMeta?.path ?? "/medewerkers",
    meta: indexAQeHAYD59nMeta || {},
    alias: indexAQeHAYD59nMeta?.alias || [],
    redirect: indexAQeHAYD59nMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/medewerkers/index.vue").then(m => m.default || m)
  },
  {
    name: indexSW1vIdCF0TMeta?.name ?? "orders-slug",
    path: indexSW1vIdCF0TMeta?.path ?? "/orders/:slug()",
    meta: indexSW1vIdCF0TMeta || {},
    alias: indexSW1vIdCF0TMeta?.alias || [],
    redirect: indexSW1vIdCF0TMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/orders/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFTPCQmRmVcMeta?.name ?? "profile",
    path: indexFTPCQmRmVcMeta?.path ?? "/profile",
    meta: indexFTPCQmRmVcMeta || {},
    alias: indexFTPCQmRmVcMeta?.alias || [],
    redirect: indexFTPCQmRmVcMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: statusWe4ssuO2WdMeta?.name ?? "status",
    path: statusWe4ssuO2WdMeta?.path ?? "/status",
    meta: statusWe4ssuO2WdMeta || {},
    alias: statusWe4ssuO2WdMeta?.alias || [],
    redirect: statusWe4ssuO2WdMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/108/nuxt/management/pages/status.vue").then(m => m.default || m)
  }
]